@import '/src/styles/variables';
@import "/src/styles/mixins";

.outerWrapper {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  bottom: 0;
  position: fixed;
  margin-right: 10px;
}

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  column-gap: 10px;
  padding: 10px 0px 5px 0px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;

  .captcha {
    margin-top: 10px;
  }

  .captchaError {
    color: red;
    margin-top: 15px;
  }
}

.navButton {
  font-family: "DM Sans", sans-serif;
  background-color: $blue;
  border: none;
  border-radius: 5px;
  color: white;
  height: 35px;
  max-width: 325px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
  cursor: pointer;
  transition: $defaultTransition;

  &:hover {
    cursor: pointer;
    background-color: $buttonHoverColor;
    transition: $defaultTransition;
  }
}

.disabled {
  background-color: grey !important;
  cursor: default;
}

.footerText {
  margin-top: 10px;
  font-size: 12px;

  a {
    color: $blue;
    text-decoration: none;
  }
}

.privacyPolicyCheckBox {
  display: flex;
  align-items: center;
  margin-top: 10px;

  p {
    font-size: 14px;
  }

  input {
    height: 15px;
    width: 15px;
    cursor: pointer;
  }

  a {
    color: $blue;
    text-decoration: none;
  }
}

.buttonsWrap {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  width: 100%;
  max-width: 325px;
}
