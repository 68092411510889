@import "./../../styles/variables";
@import "./../../styles/mixins";

.outerWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  overflow-y: auto;

  @include customScrollBar;
}

.maxSubmissionsReachedErrWrap {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.companyLogo {
  height: 60px;
  margin-bottom: 30px;
}

.companyLogoViewMode {
  height: 60px;
  margin-top: 100px;
  margin-bottom: 30px;
}

.publshMessage {
  background-color: white;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  position: fixed;
  top: 0;

  p {
    color: $blue;
  }

  button {
    margin-top: 5px;
    border: none;
    background-color: $blue;
    color: white;
    width: 150px;
    height: 30px;
    border-radius: 5px;
    font-size: 14px;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }
}

.progressBarWrap {
  width: 100%;
  max-width: 580px;
  margin: 30px 0px;
  padding: 0px 20px;
  box-sizing: border-box;
}
