@import "/src/styles/variables";
@import "/src/styles/mixins";

.buttonsWrap {
  display: flex;
  margin-top: 20px;
  column-gap: 20px;
}

.navButton {
  font-family: "DM Sans", sans-serif;
  border: none;
  color: black;
  background-color: transparent;
  font-size: 16px;
  box-sizing: border-box;
  cursor: pointer;
}

.nextBtn {
  background-color: $blue;
  border-radius: 3px;
  color: white;
  height: 40px;
  width: 100px;
  font-weight: 700;
  transition: $defaultTransition;
  cursor: pointer;

  &:hover {
    background-color: $buttonHoverColor;
    transition: $defaultTransition;
    cursor: pointer;
  }
}

.disabled {
  background-color: transparent !important;
  color: transparent !important;
  cursor: default !important;
}

.buttonsWrapLastPage {
  margin-top: 10px;
  margin-bottom: 170px;
}
