.content {
  padding: 20px 10px 10px 10px;
  max-width: 400px;
  text-align: center;

  textArea {
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 20px;
    height: 180px;
  }
}

.title {
  font-size: 24px;
  font-weight: 600;
}